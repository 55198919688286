<template>
    <!-- <div class="avatar-circle" :style="{'background-color': backgroundColor}"> -->
    <div class="avatar-circle">
        <span v-if="this.iniciales !== '-'" :class="['initials', sizeClass]">
            {{ this.iniciales }}
        </span>
        <span v-else :class="['initials', sizeClass]" class="mdi mdi-account">
        </span>
    </div>
</template>

<script>
export default {
    name: 'NoUserImage',
    props: {
        size: {
            type: String,
            validator: val => ['large', 'medium', 'small'].includes(val),
        },
        trabajador: {
            type: Object,
        },
    },
    data() {
        return {
            backgroundColor: '',
            colors: ['#cfc6ef'],
        }
    },
    mounted() {
        const random = Math.floor(Math.random() * this.colors.length);
        this.backgroundColor = this.colors[random];
    },
    updated() {
        //
    },
    computed: {
        sizeClass() {
            return this.size === 'large'
                ? 'is-size-1'
                : this.size === 'medium'
                ? 'is-size-3'
                : 'is-size-6';
        },
        perfil() {
            return this.$store.state.me.perfil;
        },
        iniciales() {
            try {
                return `${this.trabajador?.nombre[0] || '-'}${this.trabajador
                    ?.apellido_paterno[0] || ''}`;
            } catch {
                return '-';
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/styles/_variables.scss';

.avatar-circle {
    height: 100%;
    width: auto;
    text-align: center;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $primary;
    position: relative;

    .initials {
        /* line-height: 50px; */
        color: #fff;
        font-weight: bold;
    }
}
</style>
